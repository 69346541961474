// Slider

.mega-box-slider{
	position: relative;
	width: 100%;
	height: auto;
}

.mega-box-slider{
	position: relative;
	.seta-esquerda,
	.seta-direita{
		z-index: 100;
		color: #fef0db;
		position: absolute;
		top: 40%;
		display: none;
		width: 50px;
		text-align: center;
		line-height: 50px;
		height: 50px;
		background: none;
		outline: none;

		@include transition(0.3s);
		cursor: pointer;
		font-size: 50px;
                border: 0;
                margin: 0;
                padding: 0;
                &:before{
                    content: none!important;
				}



	}
        &:hover{
            .seta-esquerda, .seta-direita, .seta-esquerda-mobile, .seta-direita-mobile{
                display: block;
            }
        }
	.seta-direita{ right: 20px; }
	.seta-esquerda{ left: 20px; }
	.seta-esquerda.mobile,
	.seta-direita.mobile{
	    display: none !important;
	}
}

.slider-desktop {
    visibility: hidden;
}

.slider-mobile {
    visibility: hidden;
}
.slider-desktop{
  .item-slider{
    img{
      display:inline-block;
      max-width: 1920px;
      width: 100%;
    }
  }
}
@media (max-width: 700px){
	.slider-desktop{
		display: none;
	}
	.slider-mobile{
		display: block;
	}
	.seta-esquerda,
	.seta-direita{
		display: none !important;
	}
	.box-slider-desktop
	.seta-esquerda.mobile,
	.box-slider-desktop
	.seta-direita.mobile{
	    display: block !important;
	}
}

.slider-principal{
	&.--mobile{
		display: block;
	}
	&.--desktop{
		display: none;
	}
	.container{ padding: 0px; }
	.slick-slide:focus{ outline: none; }
	.slick-slide img{ max-width:100%; }

	.slick-dots{
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 0;
		margin-top: 20px;
		li{
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			text-indent: -9999px;
			background: #7f7f7f;
			width: 10px;
			height: 10px;
			cursor: pointer;
			&.slick-active{
				background: $corMain;
			}
		}
	}
}

.seta-outro-direita,
.seta-outro-esquerda,
.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita,
.seta-slider-simule-esquerda,
.seta-slider-simule-direita,
.seta-peca-direita,
.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-semi-direita,
.seta-adicionais-direita,
.seta-adicionais-esquerda,
.seta-adicionais-esquerda-fotos,
.seta-adicionais-direita-fotos{
	display: block;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;

	color: #322f26;
	z-index: 999;
	position: absolute;
	cursor: pointer;
	top: 40%;

}
.seta-adicionais-esquerda-fotos{
	display:block !important;
	position:relative;
	top:200px;
}
.seta-adicionais-direita-fotos{
	display:block !important;
	position:relative;
	top:-210px;
	left:97%;
}

@media (max-width: 768px){
	.seta-adicionais-esquerda-fotos, .seta-adicionais-direita-fotos{
		display: none !important;
	}
}
.seta-adicionais-esquerda,
.seta-adicionais-direita{
  z-index:1;
}


.seta-semi-esquerda,
.seta-semi-direita{ top: 55%; }

.seta-slider-de-carros-esquerda{ left: 0px; }
.seta-slider-de-carros-direita{ right: 0px; }

.seta-slider-de-carros-esquerda,
.seta-slider-de-carros-direita{ top: 53%; }

.seta-peca-esquerda,
.seta-semi-esquerda,
.seta-slider-simule-esquerda{ left: 15%; }

.seta-peca-direita,
.seta-semi-direita,
.seta-slider-simule-direita{ right: 15%; }

.seta-slider-simule-esquerda,
.seta-slider-simule-direita{ top: 40%; }

.item-slider{
	background-position: center center;
	background-size: cover;
	
}

.box-interessado{
	span{
		display: block;
		font-weight: 900;
		font-size: 48px;
		margin: 0;
		text-transform: uppercase;
		color: $branco;
		&.pequeno-texto-slider{ font-size: 16px; margin-bottom: -10px;}
		&.preco-do-carro-slider{ font-weight: 300; margin-top: -20px; margin-bottom: 20px;}
	}
}

.estou-interessado{
	display: inline-block;
	background-color: #62ddbe;
  	box-shadow: inset 0px 3px 0px 0px rgba(179, 255, 255, 0.75);
  	@include radius(10px);
  	padding: 15px;
  	text-transform: uppercase;
  	font-size: 23px;
  	font-weight: 900;
  	color: $branco;
  	i{
  		color: $branco;
  		margin-left: 10px;
  	}

    &:hover{ color: $branco; }
}


.box {
	margin-top: 50px;
}

// Escolha Um Carro

.box-escolha-um-carro{ padding-top: 35px; padding-bottom: 35px; position: relative;}
.box-sobre-carro{ padding-top: 35px; padding-bottom: 90px; position: relative;}
.box-ofertas{ padding-top: 35px; padding-bottom: 35px; position: relative; background-color: $body;}

.box-titulo-e-sub-titulo{
	margin-bottom: 70px;
	h3{
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 900;
		color: $preto;
	}

	h1{
		font-size: 30px;
		text-transform: uppercase;
		font-weight: 300;
		color: $preto;
	}
}

.box-filtro{
	span{
		text-transform: uppercase;
		font-weight: 300;
		font-size: 14px;
		margin-bottom: 20px;
		display: block;
	}
}

.filtro{
	display: inline-block;
	border: solid 1px #e5e5e5;
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 50px;
	padding-right: 50px;
	margin-bottom: 70px;
	li{
		display: inline-block;
		padding-left: 25px;
		padding-right: 25px;
		border-right: solid 1px #7e7e7e;
		a{
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 300;
			color: #636363;
		}

		&:last-child{ border-right: none; }
		&.filtro-ativo{ a{ font-weight: 400; } }
	}
}



.slider-de-carros,
.slider-ofertas,
.slider-simule,
.slider-peca,
.slider-seminovos{
	height: 300px;

	.slick-dots{
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 1000;
		margin-top: 20px;
		li{
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			text-indent: -9999px;
			background: #7f7f7f;
			width: 10px;
			height: 10px;
			cursor: pointer;
			&.slick-active{
				background: $corMain;
			}
		}
	}
}


.item-carros,
.item-interno-carros{
	vertical-align: top;
	display: inline-block;
	margin-right: 10px;
	margin-left: 10px;
	display: flex !important;
	flex-direction: column;
    justify-content: space-between;
	//border: solid 1px #eeeeee;
	@include transition(0.5s);
	overflow: hidden;
	img{
		width: 100%;
		text-align: center;
	}
}

.peca-borda{
	border: solid 3px #c10022;
	img{
		height:259px;
	}
}
.item-interno-carros{
	margin-right: 0px;
	margin-left: 0;
	
	.a-partir{
		margin-bottom: 5px;
	}

	.confira-oferta, .confira-oferta-pecas{
		margin-top: 10px;
	}

}

.box-ofertas{
	position: relative;

	.slick-arrow{
		position: absolute;
		z-index: 100;
		cursor: pointer;
		top: 250px;
	}

	.seta-slider-de-ofertas-esquerda{
		left: -85px;
	}
	.seta-slider-de-ofertas-direita{
		right: -75px;
	}
}
.slider-ofertas {
	height: auto;

	.item-carros{
		outline: none;
		background: $branco;
		padding: 15px;
		text-align: center;
		position: relative;

		padding-top: 30px;


		p{
			display: block;
			text-transform: uppercase;
			margin-bottom: 0;
		}

		.modelo{
			font-size: 24px;
			font-weight: 800;

		}
		.nome-versao{
			font-size: 16px;
			font-weight: 800;
		}

		.pre-preco{
			font-size: 14px;
			font-weight: 400;
		}
		.de{
			font-size: 14px;
			font-weight: 600;
			margin-top: 0;
		}
		.por{
			font-size: 24px;
			font-weight: 800;
			color: $main-color;
		}
		.pos-preco{
			font-size: 14px;
			font-weight: 600;
		}
		.extras{
			font-size: 20px;
			font-weight: 800;
			color: $main-color;
			padding: 5px 0px;
			margin-top: 10px;
			border-top: solid 1px $main-color;
			border-bottom: solid 1px $main-color;

			p{
				display: none;

				&.ativo{
					display: block;
				}
			}

		}
		.condicoes{
			font-size: 14px;
			font-weight: 700;
			display: inline-block;
			text-transform: uppercase;
			padding-bottom: 1px;
			border-bottom: solid 1px black;
		}

		.confira-oferta{
			padding: 6px 90px;
			margin: 15px auto 0;

		}

		a:active, a:visited, a:focus{
			text-decoration: none;
			color: inherit;
		}
	}
}

.carros-novos{
	margin-bottom: 50px;
	display: flex;
}


.slider-simule{
	.item-carros{
		vertical-align: top;
		display: inline-block;
		width: 280px;
		border: solid 1px #eeeeee;
		@include transition(0.5s);
		height: 300px;
		overflow: hidden;
	}
}

.slider-seminovos{
	.item-carros{
		background: $branco;
	}
}

.cor-semi{
	.box-titulo-e-sub-titulo{
		h3, h1{ color: $branco; }
	}
}

.nome-do-carro{
	display: block;
	font-size: 18px;
	font-weight: 900;
	color: #000;
	text-transform: uppercase;
}

.a-partir{
	display: block;
	font-size: 12px;
	font-weight: 700;
	color: #000;
	margin-bottom: 20px;
}


.confira-oferta{
	display: inline-block;
	color: $corTextoBotaoConferirOferta;
	background: $corMain;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 300;
	text-align: center;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 25px;
	padding-right: 25px;
	@include transition(0.3s);

	transform: skewx(-20deg);
	&:hover{
		background: $bgBotaoConfiraOferta;
		color: $branco;
	}

	strong{
		transform: skewx(20deg);
		display: block;
	}
}
// Simule seu consórcio

.simule-cor{ background: #fafafa; }

.no-margin{ margin: 0; }

.escolha-outro{
	margin-top: 30px;
	display: inline-block;
	background: $corMain;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 75px;
	padding-right: 55px;
	text-transform: uppercase;
	font-weight: 900;
	color: $branco;
	i{
		margin-left: 20px;
	}
	@include transition(0.3s);

	&:hover{
		background: #bb3c2b;
		color: $branco;
	}
}

.cor-semi{ background: $preto; }

// Semi Novos

.procurar-semi-novo{
	margin-bottom: 35px;
	input{
		background: none;
		border: solid 1px $branco;
		height: 80px;
		color: $branco;
		border-right: none;
		&:focus{
			outline: none;
		}
	}

	.input-group-addon{
		background: none;
		border-color: $branco;
		i{
			color: $branco;
			font-size: 20px;
		}
	}
}

// Pós Vendas

.box-atendimento-pos{

	padding-bottom: 120px;
	background-size: cover;
	background-position: center center;
	background-color: $body;
	.box-titulo-e-sub-titulo{
		h3, h1{ color: $branco; }
	}

	.col-md-4{
		background-repeat: no-repeat;
		background-position: center right;
		background-size: contain;
    	padding: 0 25px;
		min-height: 200px;
		position: relative;
		margin-top: 35px;
		.text{
			color: #8b3f29;
			font-weight: 800;
			width: 50%;
			text-align: left;
			h5{
				font-size: 16px;
				margin-bottom: 10px;
				text-transform: uppercase;
			}
			p{
				font-size: 14px;
				color: #000;
			}
		}

		.plus{
			position: absolute;
			top: -10px;
			right: -5px;
		}
	}
}

.botao-pos-venda{
	display: inline-block;
	border-bottom: solid 5px transparent;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 5px;
	padding-bottom: 5px;
	width: 100%;
	position: relative;
	@include radius(16px);
	@include transition(0.3s);

	img{ vertical-align: top; margin-right: 25px; }

	.texto-do-botao{
		display: inline-block;
		margin-top: 10px;
		span{
			display: block;
			color: $branco;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 18px;
			strong{ font-weight: 900; }
			margin-bottom: -8px;
		}
	}

	i{
		position: absolute;
		right: 25px;
		color: $branco;
		top: 30%;
		font-size: 30px;
	}

	&.pos-whats{
		background: #35a648;
		border-color: #40c957;

		&:hover{
			background: #40c957;
			border-color: #35a648;
		}
	}

	&.pos-pecas{
		background: #228bab;
		border-color: #36a9cc;

		&:hover{
			background: #36a9cc;
			border-color: #228bab;
		}
	}

	&.pos-revisao{
		background: #b45545;
		border-color: #ff7c66;

		&:hover{
			background: #ff7c66;
			border-color: #b45545;
		}
	}
}

// Renault Atendimento

.renault-atendimento{
	.box-titulo-e-sub-titulo{
		h3, h1{ color: $preto; }
	}

	.botao-pos-venda{
		background: #252525;
		border-color: #636363;
		padding-top: 10px;
		padding-bottom: 10px;

		&:hover{
			background: #636363;
			border-color: #252525;
		}
	}

	.col-md-4{
		background-repeat: no-repeat;
		background-position: center right;
		background-size: contain;
    	padding: 0 25px;
		min-height: 200px;
		position: relative;
		margin-top: 25px;
		.text{
			color: #8b3f29;
			font-weight: 800;
			width: 50%;
			text-align: left;
			h5{
				font-size: 16px;
				margin-bottom: 10px;
				text-transform: uppercase;
			}
			p{
				font-size: 14px;
				color: #000;
			}
		}

		.plus{
			position: absolute;
			top: -10px;
			right: -5px;
		}
	}

}

// Itenra Carros Novos

.pequeno-texto{
	text-align: center;
	margin-bottom: 50px;
	p{
		font-size: 18px;
		font-weight: 300;
	}
}

.box-mini-mapa{
	background: $corMain;
	padding-top: 15px;
	padding-bottom: 15px;

	span{
		color: $branco;
		text-transform: uppercase;
		a{
			font-weight: 700;
		}
	}
}

.nome-da-categoria{
	padding-bottom: 15px;
	border-bottom: solid 1px #e5e5e5;
	margin-bottom: 20px;
	span{
		text-transform: uppercase;
		font-weight: 500;
		font-size: 18px;
		color: #636363;
	}
}

// Empresa

.sobre-pagina{
	border: solid 1px #e5e5e5;
	display: inline-block;
	padding-left: 95px;
	padding-right: 95px;
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	z-index: 0;
	margin-top: 0px;
	background: none;

	.box-titulo-e-sub-titulo{
		margin-bottom: 50px;
	}

	.pequeno-texto{
		margin-bottom: 30px;
		text-align: left;
	}

	.botao-pos-venda, .pos-whats{
    	padding-top: 10px;
    	margin-top: 50px;
	}
}

.box-imagem-interna{
	height: 380px;
	img{
		width: 100%;
		max-width: 1180px;
	}
}

.box-fotos-empresa{
	background: #fafafa;
	padding-top: 55px;
	padding-bottom: 85px;
	border-top: solid 1px #e5e5e5;
	border-bottom: solid 1px #e5e5e5;
	margin-top: 50px;
}

.item-galeria{
	width: 100%;
	height: 370px;
	background-position: center center;
	background-size: cover;
}

.item-pager-galeria{
	display: inline-block;
	margin-right: -1px;
}

.pager-galeria{
	margin-bottom: 50px;
}

.ligamos-pra-voce{
	text-align: center;
	padding-bottom: 22px;
	border-color: #252525;
	background: #636363;
	padding-top: 10px;

	img{
		left: 30px;

	}

	.texto-do-botao{
		margin-top: 15px;
		span{ display: inline-block; }
	}

	&:hover{
		background: #252525;
		border-color: #636363;
	}
}
.box-carros-novos{
	padding-bottom: 60px;
}
// Contato

.box-contato{
	.sobre-pagina{
		display: block;
	}
}

.icone-telefone{
	display: inline-block;
	margin-bottom: 40px;
	vertical-align: top;
	margin-top: 10px;
	i{
		font-size: 20px;
	}
}

.caixa-numero{
	display: inline-block;
	margin-left: 15px;
	span{
		display: block;
		font-size: 14px;
	}
}

.contato{
	margin-bottom: 50px;
	textarea, input{
		@include radius(0);
		margin-bottom: 15px;
	}
}


// Seguro

.botao-e-informacao{
	margin-bottom: 20px;
}

.botao-mais-infos{
	width: 100%;
	position: relative;
	background: #eeeeee;
	padding-top: 15px;
	padding-bottom: 15px;
	text-align: center;
	cursor: pointer;

	span{
		font-size: 16px;
		font-weight: 300;
	}

	.fa-plus{
		position: absolute;
		right: 15px;
		top: 18px;
		font-size: 18px;
		opacity: 1;
	}

	.fa-minus{
		position: absolute;
		right: 15px;
		top: 18px;
		font-size: 18px;
		opacity: 0;
	}
}

.box-mais-info{
	background: #eeeeee;
	padding-top: 35px;
	padding-bottom: 35px;
}

.texto-mais-info{
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 30px;
}

.botoes-menores{
	display: inline-block;
	color: $branco;
	background: $bgBotaoConfiraOferta;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 300;
	text-align: center;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 20px;
	@include transition(0.3s);
	@include radius(10px);

	&:hover{
		color: $corTextoBotaoConferirOferta;
		background: $corMain;
	}
 }

.menor-ativo{
	background: $corMain;
	margin-left: 30px;
}

.mais-ativo{
	background: #636363;
	color: $branco;

	.fa-plus{ opacity: 0; }

	.fa-minus{ opacity: 1; }
}

// Consorcio

.box-escolha-carro{
	position: relative;
	/*margin-top: 100px;*/
	margin-bottom: 100px;
	.seta-slider-simule-esquerda,
	.seta-slider-simule-direita{
		top: 20%;
	}
}

.interna-consorcio{
	margin-top: 0;
	padding-top: 40px;
	background: #fafafa;

}

// Interna

.card-escolha-versao{
	padding: 50px;
	background: $branco;
	border: solid 1px #e5e5e5;
}

.nome-e-ano{
	strong{ font-weight: 900; }
	font-size: 48px;
	display: block;

}

.versao-do-carro{
	strong{ font-weight: 900; }
	font-size: 18px;
	display: block;
	margin-bottom: 25px;
}

.texto-legal{
	font-size: 12px;
	color: #636363;
}

.imagem-do-carro{
	text-align: center;
	margin-bottom: 30px;
	img{
/*		width: 100%;
		max-width: 225px;*/
	}
}

.card-escolha-versao{
	.imagem-do-carro{
		img{
			width: 100%;
			display: inline-block;
			max-width: 280px;
		}
	}
}

.escolha-versao{
	height: 35px;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 25px;
	font-size: 14px;
}

.box-de-preco-da-versao{
	.parcelas-consorcio{
		display: block;
		text-align: center;
		font-weight: 900;
		text-transform: uppercase;
		color: #636363;
	}

	.valor-consorcio{
		display: block;
		text-align: center;
		text-transform: uppercase;
		color: #636363;
		font-size: 30px;
		font-weight: 300;
		em{
			font-style: normal;
			text-transform: none;
			font-size: 14px;
		}
	}

	.credito-consorcio{
		display: block;
		text-align: center;
		font-size: 12px;
		color: #636363;
		margin-top: 25px;
	}

	.valor-do-credito-consorcio{
		text-align: center;
		strong{ font-weight: 900; }
		font-size: 18px;
		color: #636363;
		display: block;
	}
}


.card-plano-consorcio{
	padding-top: 35px;
	padding-bottom: 12px;
	background: $branco;
	border: solid 1px #e5e5e5;
	margin-bottom: 20px;

	.tempo-do-plano,
	.contemplados{
		color: #252525;
		font-size: 14px;
		display: block;
		margin-bottom: 20px;
	}

	.valor-do-plano{
		color: #252525;
		strong{ font-weight: 900; }
		display: block;
		font-size: 24px;
		margin-bottom: 20px;
	}


}

.titulo-escolha-um-plano{
	display: inherit;
	margin-top: 60px;
	text-transform: uppercase;
	font-size: 18px;
	margin-bottom: 50px;
}

// Simulacao
.card-form-financiamento{
	padding: 50px;
	background: $branco;
	border: solid 1px #e5e5e5;
	margin-bottom: 20px;
	h1{
		display: inherit;
		text-transform: uppercase;
		font-size: 18px;
		margin-bottom: 20px;
	}
}



// Modal

.custom-modal{
	display: inline-block;
	background: $corMain;
	padding-top: 45px;
	padding-left: 100px;
	padding-right: 100px;
	@include radius(20px);
	border-top-right-radius: 0;
	position: relative;
	padding-bottom: 50px;
}

.novo-modal{
	padding: 0;
	background: $branco;
	color: $preto;

	.modal-footer{
		background: $main-color;
		text-align: center;
	}
	a.close-modal{
		display: none;
	}
	.fechar-modal{
		background: none;
		right: -5px;
		a{
			color: inherit;
		}
		i{
			color: $preto;
		}
	}
	.tag-preencher{
		span, i{
			color: black;
			font-weight: 700;
			font-size: 26px;
		}
	}
	.loja p{
		font-weight: 600;
		text-transform: uppercase;
	}
	.btn{
		border-radius: 0;
	}
}

#box-modal-floater{
	padding: 0px 0px 0px 0px;
	background: white;

	.tag-preencher{
		span{
			color: black;
		}
	}
}

.revisao-modal{
	max-width: 650px;
}

.tag-preencher{
	width: 100%;
	text-align: center;
  padding:9px 0;
	span{
		text-transform: uppercase;
		font-weight: 300;
		color: $branco;
		font-size: 20px;
		display: block;
		margin-bottom: 10px;
	}

	i{
		color: $branco;
		font-size: 40px;
	}
}


.modal-contato{
	label{
		color: #FFF;
	}

	input, textarea, select{
		color: #FFF;
		border: none;
		border-bottom: solid 1px #c2c2c2;
		background: none;
		@include radius(0);
		box-shadow: none;

		&:focus{
			border: solid 1px $branco;
			box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
		}
	}
	input, textarea, select{
		&[disabled], &[readonly]{
			background-color: darken($corMain, 10%);
		}
	}
	select{
		padding: 6px;
	}

	select option{
		color: #1b1b1b;
	}
	textarea{
		min-height: 80px;
	}

	::-webkit-input-placeholder {
	   color: $branco;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: $branco;
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: $branco;
	}

	:-ms-input-placeholder {
	   color: $branco;
	}

	span{
		color: $branco;
		text-align: center;
		font-size: 12px;
		display: inline-block;
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.enviar-modal{
		background: #313131;
		color: $branco;
		width: 100%;
		display: inline-block;
		text-align: center;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
		position: relative;
		border: none;
		padding-top: 20px;
		padding-bottom: 20px;

		i{
			position: absolute;
			right: 20px;
			top: 15px;
			font-size: 30px;
			color: $branco;
		}

	}
}

.fechar-modal{
	width: 60px;
	right: 60px;
	position: absolute;
	right: 0;
	top: 0;
	background: #313131;

	text-align: center;
	line-height: 45px;
	z-index: 100;

	i{
		color: $branco;
		font-size: 30px;
		@include transition(0.3s);
	}

	&:hover{
		i{ color: $corMain; }
	}
}


// Peças Internas

.box-quadro-de-pecas{
	background: #fafafa;
	padding-top: 50px;
	padding-bottom: 50px;
	border-top: solid 1px #ededed;
	border-bottom: solid 1px #ededed;
}

.titulo-box-filtro-pecas{
	background: #333333;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
	border-bottom: solid 1px #636363;
	span{
		font-weight: 700;
		text-transform: uppercase;
		color: $branco;
		font-size: 12px;
	}
}

.pesquisa-filtro{
	background: #252525;
	border-bottom: solid 1px #636363;
	padding: 10px;

	.input-group,
	.form-group{ width: 100%; }

	input{
		border: solid 1px $branco;
		height: 40px;
		background: none;
		@include radius(0);
		box-shadow: none;
		border-right: none;

		&:focus{
			border: solid 1px $branco;
			box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
		}
	}

	::-webkit-input-placeholder {
	   color: $branco;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: $branco;
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: $branco;
	}

	:-ms-input-placeholder {
	   color: $branco;
	}

	.input-group-addon{
		background: none;
		@include radius(0);
		border-left: none;
		border-color: $branco;
		i{ color: $branco; }
	}
}

.clique-carro{
	display: block;
	background: #1b1b1b;
	padding-top: 30px;
	padding-bottom: 30px;
	width: 100%;
	position: relative;
	cursor: pointer;
	@include transition(0.3s);
	border-bottom: solid 1px #636363;
	padding-left: 10px;
	padding-right: 10px;

	span{
		@include transition(0.3s);
		text-transform: uppercase;
		font-weight: 300;
		color: $branco;
	}

	&:hover{
		background: $corMain;
	}

	i{
		color: $branco;
		font-size: 20px;
		margin-right: 10px;
		vertical-align: middle;
		opacity: 0;
		@include transition(0.3s);

	}

	&.feita-escolha{
		background: $corMain;
		border-color: $corMain;

		span{ font-weight: 700; }

		i{ opacity: 1 }
		.fa-times{
			position: absolute;
			right: 0; top: 25px;
		}
	}
}


.barra-ordenar{
	background: #e3e3e3;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 25px;
	padding-bottom: 25px;
	margin-bottom: 20px;

	span{
		text-transform: uppercase;
		color: #0f0f0f;
	}

	.nome-da-barra{
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 900;
		color: #1b1b1b;
	}
}

.ordenar{
	display: inline-block;
	margin-left: 10px;
	li{
		display: inline-block;
		padding-left: 10px;
		padding-right: 10px;
		border-right: solid 1px #0f0f0f;

		a{
			text-transform: uppercase;
			color: #0f0f0f;
		}

		&:last-child{ border-right: none; }
	}
}

.caixa-de-resultados{
	.item-carros{
		width: 265px;
		margin-left: 7px;
		margin-bottom: 30px;
		margin-right: 7px;
	}
}

.item-carros-pecas{
	width: 265px;
	margin-left: 7px;
	margin-bottom: 30px;
	margin-right: 7px;
}
// Interna Carro

.botoes-internos-carro{
	background: none;
	margin-top: 70px;
	margin-bottom: 70px;
}

.box-versoes-com-mais{
	padding-bottom: 35px;
}

.box-fotos-do-carro{
	background: #fafafa;
	border-top: solid 1px #e5e5e5;
	padding-top: 35px;
	padding-bottom: 35px;
}

.interior-exterior{ margin-bottom: 60px; position:  relative;}


.interior,
.exterior{
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	background: #636363;
	color: #e5e5e5;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 700;
	font-size: 14px;
	margin-right: 20px;
	padding-left: 65px;
	padding-right: 65px;

	cursor: pointer;
	@include transition(0.3s);

	&:hover{
		background: $corMain;
		color: $branco;
	}

	&.ativo-in-ex{
		background: $corMain;
		color: $branco;
	}

}

.ativo-in-ex:before{
	content: "";
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	width: 0;
	height: 0;
	position: absolute;
	bottom: -15px;
	left: 12px;

	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #c10022;
}


.cards-adicionais{
	margin-bottom: 35px;
	position: relative;

	.slick-slide:focus{ outline: none; }
}

.card-adicional{
	margin-right: 10px;
	margin-left: 10px;
	width: 290px;
	padding-top: 30px;
	text-align: center;
	display: inline-block;
	border: solid 1px #eeeeee;
	display: flex !important;
    flex-direction: column;
    justify-content: space-between;
	@include transition(0.5s);

	.confira-oferta, .confira-oferta-pecas{ margin-bottom: 20px; }

	&:hover{
		opacity: 1;
	}

	&.adicional-ativo{ opacity: 1; }

}

.nome-do-carro-adicional,
.modelo-do-carro-adicional,
.preco-do-carro-adicional{
	display: inline-block;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	font-size: 24px;

	strong{ font-weight: 900; }
}
.modelo-do-carro-adicional{ font-size: 18px; }
.preco-do-carro-adicional{ font-size: 16px; margin-bottom:  20px;}


.thumb-foto-adicional{
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	img{
		display: inline-block;
		width: 100%;
		max-width: 185px;
	}
}

.adicionais-do-carro{
	cursor:pointer;
	display: none;
	padding-top: 30px;
	padding-bottom: 30px;
	background: #1b1b1b;
	li{
		color: $branco;
	}
}

.ver-itens-adicional{
	display: inline-block;
	width: 100%;
	background: #f2f2f2;
	cursor: pointer;
	padding-top: 15px;
	padding-bottom: 15px;
	text-transform: uppercase;
	font-weight: 900;
	color: #929292;
	@include transition(0.3s);

	&:hover{
		color: #252525;
	}
}

.seta-adicionais-direita{
	right: -5%;
	border: solid 1px #252525;
	i{ color: #252525; @include transition(0.3s); }
	background: none;
	@include transition(0.3s);

	&:hover{
		background: $corMain;
		border-color: $corMain;
		i{ color: $branco; }
	}
}

.seta-adicionais-esquerda{
	left: -5%;
	border: solid 1px #252525;
	i{ color: #252525; @include transition(0.3s); }
	background: none;
	@include transition(0.3s);

	&:hover{
		background: $corMain;
		border-color: $corMain;
		i{ color: $branco; }
	}
}

.seta-versao-esquerda,
.seta-ultimo-esquerda,
.seta-ultimo-direita,
.seta-versao-direita{
	display: inline-block;
	position: absolute;
	text-transform: uppercase;
	font-size: 14px;
	top: -50px;
	color: #828282;
	font-weight: 900;
	@include transition(0.3s);
	vertical-align: top;
	cursor: pointer;

	i{
		display: inline-block;
		width: 40px;
		height: 40px;
		border: solid 1px #252525;
		@include transition(0.3s);
		color: #252525;
		text-align: center;
		line-height: 40px;
		margin-right: 15px;
		font-size: 24px;
	}

	&:hover{
		color: #252525;
		i{
			background: $corMain;
			color: $branco;
			border-color: $corMain;
		}
	}
}

.seta-versao-esquerda{ left: 0;}
.seta-versao-direita{
	right: 0;
	i{
		margin-left: 10px;
		margin-right: 0;
	}
}

.item-outro-slider{
	display: inline-block;
	width: 280px;
	background: $branco;
	height: 450px;
	margin-left: 10px;
	margin-right: 10px;
}

.thumb-imagem-outro{
	margin-bottom: 25px;
	img{
		width: 100%;
	}
}

.outro-titulo{
	display: inline-block;
	width: 100%;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 900;
	color: #636363;
	margin-bottom: 30px;
	text-align: center;
}

.outro-texto{
	padding-left: 15px;
	padding-right: 15px;
	p{
		font-weight: 300;
		font-size: 12px;
		color: #636363;
	}
}

.outro-slider{
	.slick-dots{
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 1000;
		margin-top: 50px;
		li{
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			text-indent: -9999px;
			background: #7f7f7f;
			width: 10px;
			height: 10px;
			cursor: pointer;
			&.slick-active{
				background: $corMain;
			}
		}
	}
}

.seta-outro-direita{ right: 0px; }
.seta-outro-esquerda{ left: 0px; }


.box-escolha-um-carro{
	
	.item-versao{
		.sobre-pagina{
			margin-top: 0;

			.card-adicional{
				opacity: 1;
				border: none;
			}
		}
	}
}


.box-escolha-um-carro{
	.setas-versoes{
		.seta-ultimo-direita,
		.seta-ultimo-esquerda{
			top: 40%;
			z-index: 100;
			background: $branco;
		}

		.seta-ultimo-direita{ right: -25px; }
		.seta-ultimo-esquerda{ left: -25px; }
	}
}

.item-pager-galeria{ cursor: pointer; }


.esconder-imagem{
	height: 0px;
	overflow-y: hidden;
	display: block !important;
}

.box-radios{
  text-align: center;
	.radio{
		display:inline-block;
    margin-right: 10px;
	}
}

///Peças e acessórios
.nome-da-peca, .nome-da-peca-destaque{
	display: block;
	font-size: 18px;
	font-weight: 900;
	color: #636363;
	text-transform: uppercase;
}

.codigo {
	display: block;
	font-size: 10px;
	font-weight: 700;
	color: #777;
	margin-bottom: 20px;
}

.a-partir-pecas-de{
    span {
        text-decoration: line-through;
    }
}

.parcelas{
    color:#636363;
    strong {
        color: $corMain;
    }
}

.a-partir-pecas{
		display: block;
		font-size: 20px;
		font-weight: 700;
		color: #d01b27;
		small{
			font-weight: 600;
			font-size: 70%;
			color:#636363;
		}
}
.confira-oferta-pecas{
	display: inline-block;
	width: 100%;
	color: $corTextoBotaoConferirOferta;
	background: $corMain;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 300;
	text-align: center;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 25px;
	padding-right: 25px;
	@include transition(0.3s);
	&:hover{
		background: $bgBotaoConfiraOferta;
		color: $branco;
	}
}

.items-do-filtro{
	a{
		border-right:solid 1px #636363;
	}
}
////////////// peças interna ///////////


.box-interna-pecaacessorio{
    margin-top: 50px;

    .nome-pecaacessorio-interna{
        color: $corMain;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
    }


    .fotos-do-item{
        position: relative;
        img{
            width: 100%;
            max-height: 580px;
        }
        .seta-esquerda,
        .seta-direita,
        .seta-direita-mobile,
        .seta-esquerda-mobile{
            z-index: 100;
            color: #FFF;
            position: absolute;
            top: 45%;
            display: none;
            width: 50px;
            text-align: center;
            line-height: 50px;
            height: 50px;
            background: transparent;
            border: 0;
            margin: 0;
            padding: 0;
            @include transition(0.3s);
            cursor: pointer;
            font-size: 50px;
        }
        &:hover{
            .seta-esquerda,.seta-direita, .seta-direita-mobile, .seta-esquerda-mobile{
                display: block;
            }
        }
        .seta-direita,
        .seta-direita-mobile{ right: 0; }
        .seta-esquerda,
        .seta-esquerda-mobile{ left: 0; }
    }

    .galeria-thumb{
        background: #e6e6e6;
        padding-top: 10px;
        text-align: center;
        .item-thumbs{
            max-width: 100px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 10px;
            img{
                display: inline-block;
                cursor: pointer;
                width: 100%;
                max-width: 95px;
            }
        }
    }

}

.caixa-descricao{
    border: solid 1px #313131;
    margin-top: 30px;
    padding-bottom: 15px;

    margin-bottom: 50px;

    .caixa-titulo{
        display: inline-block;
        width: 100%;
        padding-left: 15px;
        height: 60px;
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 15px;
        background: #313131;
        line-height: 60px;
    }

    .info-descricao{
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        padding: 10px 15px;
    }
}
.lead-pecaacessorio{
    border-top: solid 1px #fff;
    background: #636363;
    text-align: center;
}
.tag-lead-interna{
    display: inline-block;
    text-transform: uppercase;
    font-weight: 800;
    color: $branco;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
}
.form-interna-pecaacessorio{
    input{
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        color: #1b1b1b;
    }

    textarea{
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        color: #1b1b1b;
    }
}
.box-inputs{
    padding-left: 10px;
    padding-right: 10px;
}
.tag-lead-menor{
    color: $branco;
    font-size: 12px;
    font-weight: 800;
    display: inline-block;
}
.box-radios{
    .radio{
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        label{
            color: #615f6c;
            font-size: 11px;
            font-weight: 300;
            color: $branco;
        }
    }
}

.enviar-lead-interna{
    margin-top: 15px;
    margin-bottom: 10px;
    display: inline-block;
    background: red;
    color: white;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    font-weight: 800;
    font:{
        size: 16px;
    };
    @include transition(0.3s);
    @include radius(1px);

    i{ font-size: 12px; }
    &:hover{
        background-color: darken(red, 10%);
    }
}

.revisao-programada-banner{
	width:100%;
	height: 260px;
	display:block;
	background: transparent url('../img/slider/revisao-programada.jpg') no-repeat scroll center center;
	background-size: cover;
}
.lead-revisaoprogramada{
    background-color: $corMain;
    padding: 20px;
}

.box-revisaoprogramada {

}

.table{
    width: 100%;
    thead{
        font-weight: bold;
    }
}
.table-car-links a{
    background-color: $corMain;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
}
.table-striped{
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
}

.bold {
    font-weight: bold;
}
.peca-content{
	height:200px;
}


.mega-box-slider{
	.slide-left{
		display:none;
	}
	.slide-right{
		display:none;
	}
	&:hover{
		.slide-left{
			display: block;
		}
		.slide-right{
			display: block;
		}
	}
}
